<template>
  <div id="app">
    <MainMenu />
    <div class="main-content">
      <transition name="fade" mode="out-in" >
        <router-view :key="$route.fullPath"/>
      </transition>
    </div>
    <ResponsiveMenu :key="reloadMenu" />
    <Footer />
  </div>
</template>

<script>
import MainMenu from '@/components/MainMenu.vue'
import ResponsiveMenu from '@/components/ResponsiveMenu.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    MainMenu,
    ResponsiveMenu,
    Footer
  },
  data() {
    return {
      prevHeight: 0,
      reloadMenu: 0
    };
  },
  watch: {
    '$route' () {
      this.reloadMenu += 1;
    }
  }
}
</script>

<style lang="scss">
  @import "./styles/_variables.scss";
  @import "./styles/_responsive.scss";
  @import "./styles/_progressBar.scss";
  @import "//fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons";

  html {
    height: 100%;

    body {
      margin: 0;
      height: 100%;
      font-size: unset;
      line-height: unset;
      font-weight: unset;
      font-family: unset;
      text-align: unset;
      color: unset;

      .grecaptcha-badge {
        visibility: hidden;
      }
      #app {
        font: Regular var(--unnamed-font-size-16)/var(--unnamed-line-spacing-18) var(--unnamed-font-family-helvetica-neue);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: $main-text-color;
        font-family: helvetica;

        .main-content {
          max-width: 1365px;
          width: auto;
          margin: 0 auto;
          background-image: url("./assets/bg.png");
          background-position: center;
          background-size: 271px 80px;
          position: relative;
          padding-bottom: 80px;
          min-height: calc(100vh - 588px);
        }
      }
      .breadcrumb {
        text-align: left;
        color: $second-text-color;
        display: flex;

        * {
          background-color: white;
        }
        .breadcrumb-item {
          color: $second-text-color;
          text-decoration: unset;
          cursor: pointer;
          padding: 0 5px;

          &:first-child {
            padding-left: unset;
          }
          &:hover {
            text-decoration: underline;
          }
        }
        .breadcrumb-current-item {
          color: $second-bg-color;
          font-weight: 600;
          padding-left: 5px;
        }
      }
    }
  }

  img {
    &[lazy="error"],
    &[lazy="loading"] {
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      width: 50px !important;
      margin: 0 auto !important;
      background-size: 50px !important;
      background-position: 50% 50% !important;
      object-fit: contain !important;
      display: block !important;
      height: 100% !important;
    }

    @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  }

  h3 {
    color: $second-bg-color;
    font-size: 2rem;
    font-weight: normal;

    &.black {
      color: $main-text-color;
    }
  }

  button.action {
    display: block;
    border: none;
    background: $main-text-color;
    color: #ffffff;
    padding: 10px 50px;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
    border-top: solid 8px white;
    border-bottom: solid 8px white;

    &:focus {
      outline: none;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.5s;
    transition-property: opacity;
    transition-timing-function: linear;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }

  .reference {
    .central-container {
      .detail {
        ul {
          text-align: left;
        }
      }
    }
  }

  @media screen and (max-width: 970px) {
    html {
      body {
        #app {
          .main-content {
            min-height: calc(100vh - 551px);
          }
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    html {
      body {
        #app {
          .main-content {
            min-height: calc(100vh - 566px);
          }
        }
      }
    }
  }
</style>
