import axios from 'axios'

const baseUrl = process.env.VUE_APP_BASE_API_URL;

export const BACKEND_API = axios.create(
    {
      baseURL: baseUrl
    }
);

export const API_ROUTE = {
    'HOME_SLIDER': '/home/slider',
    'HOME_STATICBLOCK': '/home/staticblock',
    'FOOTER_LINKS': '/footer/links',
    'TECHNOS': '/technos/list',
    'BRAND': '/reference/brand/',
    'REFERENCES': '/reference/list',
    'SERVICES': '/edito/page/services',
    'EXPERTISE': '/edito/page/expertises',
    'RECRUTEMENT': '/edito/page/recrutement',
    'SEO': '/edito/page/seo',
    'CONTACT': '/contact/form',
    'AGENCE': '/edito/page/agence',
    'MENTIONS_LEGALES': '/edito/page/mentions-legales',
};