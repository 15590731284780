<template>
  <div class="footer">
    <div class="contact-us">
      <div class="central-container">
          <div class="columns-container">
            <div class="logo column">
                <img src="../assets/contact_picto.png" width="41" height="28" />
                <span>Vous avez un projet ?</span>
            </div>
            <div class="column link">
                <span>
                  <router-link to="/contact/">Contactez-nous</router-link>
                </span>
            </div>
          </div>
        </div>
    </div>
    <div class="footer-links">
      <div class="central-container">
        <div class="col logo">
          <img class="logo" src="../assets/LogoFlaxeo-RVB-Blanc-turquoise.webp" width="149" height="50" />
        </div>
        <div class="sep"></div>
    
        <div class="col" v-for="(link, index) in links" :key="index">
          <ul v-if="index !== 0" class="bottom-links">
            <li v-for="(sublink, index) in link.sublinks" :key="index">
              <router-link :to="sublink.link">{{sublink.name}}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BACKEND_API } from "../config/api";
import { API_ROUTE } from "../config/api";

export default {
  name: "HomeSlider",
  data: () => {
    return {
      errorMessage: "",
      links: ""
    };
  },
  mounted() {
    BACKEND_API.get(API_ROUTE.FOOTER_LINKS)
      .then(response => {
        this.links = response.data;
      })
      .catch(e => {
        this.errorMessage = e;
      });
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/_variables.scss";
  .footer {
    background: $main-bg-color;

    .contact-us {
      background: $second-bg-color;

      .central-container {
        height: 100px;
        max-width: 1365px;
        width: auto;
        margin: 0 auto;

        .columns-container {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          height: 100%;
          max-width: 865px;
          margin: 0 auto;
          padding: 0 20px;

          .logo {
            span {
              font-size: 20px;
              line-height: 20px;
              font-weight: bold;
              margin-left: 45px;
              text-transform: uppercase;
            }
          }
          .column {
            display: flex;
            align-items: center;

            a {
              background: #101416;
              padding: 10px 40px;
              border-radius: 30px;
            }
          }
        }
      }
    }
  }
  .footer-links {
    min-height: 298px;
    .central-container {
      max-width: 1100px;
      width: auto;
      margin: 0 auto;

      .sep {
        background: $second-bg-color;
        margin: 0 auto;
        width: 80%;
        height: 3px;
      }
      .col {
        padding: 10px 5px 0;
        font-weight: 600;

        &.logo {
          padding: 50px 0;

          img {
            height: 50px;
          }
        }
        .bottom-links {
          list-style: none;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          flex-wrap: wrap;
          flex-grow: 1;
          padding: 30px 0;

          li {
            text-transform: uppercase;
            padding: 5px 20px;
          }
        }
        &+.col {
          max-width: 440px;
          margin: 0 auto;
          padding-top: 0;
          padding-bottom: 10px;
          font-weight: unset;

          ul {
            li {
              padding: 5px 0;
              a {
                font-size: 0.9rem;
                text-transform: initial;
              }
              & a {
                color: $second-bg-color;
              }
            }
          }
        }
      }
    }
  }
  a {
    text-decoration: none;
    color: $header-link-color;
  }

  @media screen and (max-width: 640px) {
    .footer {
      .contact-us {
        padding-bottom: 15px;

        .central-container {
          .columns-container {
            justify-content: center;

            .column {
              &.logo {
                span {
                  margin-left: 10px;
                  font-size: 1rem;
                  padding-top: 3px;
                }
              }
              &.link {
                width: 100%;
                justify-content: center;
              }
            }
          }
        }
      }
      .footer-links {
        .central-container {
          .col {
            .bottom-links {
              li {
                width: 100%;
                padding: 5px 0;
              }
            }
            &+.col {
              .bottom-links {
                //padding: unset;
                //margin: unset;
              }
            }
          }
        }
      }
    }
  }
</style>