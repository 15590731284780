import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from "@/script/progress.js"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import ('@/views/Home.vue')
  },
  {
    path: '/expertise',
    name: 'Notre expertise',
    component: () => import ('@/views/Expertise.vue')
  },
  {
    path: '/nos-references',
    name: 'Nos références',
    component: () => import('@/views/NosReferences.vue')
  },
  {
    path: '/nos-references/:customer',
    name: 'Nos références',
    component: () => import('@/views/Reference.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('@/views/Services.vue')
  },
  {
    path: '/agence',
    name: 'Agence',
    component: () => import('@/views/Agence.vue')
  },
  {
    path: '/methodologie',
    name: 'Methodologie',
    component: () => import('@/views/Agence.vue')
  },
  {
    path: '/recrutement',
    name: 'Recrutement',
    component: () => import('@/views/Recrutement.vue')
  },
  {
    path: '/seo',
    name: 'Seo',
    component: () => import('@/views/Seo.vue')
  },
  {
    path: '/mentions-legales',
    name: 'MentionsLegales',
    component: () => import('@/views/MentionsLegales.vue')
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import('@/views/PageNotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  NProgress.set(0.2)
  next()
})
router.afterEach(() => {
  setTimeout(() => NProgress.done(), 500)
})

export default router
