<template>
  <div class="responsive-menu-container">
    <transition name="slide">
      <div class="responsive-menu" v-if="isMenuDisplayed">
        <ul>
         <!-- <li>
            <router-link to="/agence/" rel="nofollow">AGENCE</router-link>
          </li>-->
          <li>
            <router-link to="/expertise/" rel="nofollow">EXPERTISES</router-link>
          </li>
           <li>
            <router-link to="/services/" rel="nofollow">SERVICES</router-link>
          </li>
          <li>
            <router-link to="/nos-references/" rel="nofollow">RÉFÉRENCES</router-link>
          </li>
          <li>
            <router-link to="/seo/" rel="nofollow">SEO</router-link>
          </li>
          <li>
            <router-link to="/recrutement/" rel="nofollow">RECRUTEMENT</router-link>
          </li>
          <li>
            <router-link to="/contact/" rel="nofollow">CONTACT</router-link>
          </li>
        </ul>
      </div>
    </transition>
    <div class="resp-menu-trigger">
      <div v-show="isMenuDisplayed" v-on:click="isMenuDisplayed = false" class="close">X</div>
      <div v-show="!isMenuDisplayed" v-on:click="isMenuDisplayed = true" class="open">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResponsiveMenu",
  data: () => {
    return {
      isMenuDisplayed: false
    };
  },
  methods: {
    showRespMenu: () => {}
  }
};
</script>

<style lang="scss">
@import "@/styles/_variables.scss";
    .slide-enter-active,
    .slide-leave-active
    {
        transition: transform 0.2s ease;
    }

    .slide-enter,
    .slide-leave-to {
        transform: translateX(-100%);
        transition: all 150ms ease-in 0s
    }
.responsive-menu {
  position: fixed;
  left: 0;
  top: 0;
  background: $main-bg-color;
  height: 100%;
  width: 75%;
  z-index: 11;

  ul {
    list-style: none;
    text-align: left;
    padding-left: 0;

    li {
      a {
        padding: 0 30px;
        text-transform: uppercase;
        line-height: 40px;
        text-decoration: none;
        color: #ffffff;
      }
    }
  }
}

.resp-menu-trigger {
  display: none;
  position: fixed;
  top: 25px;
  font-weight: bold;
  right: 25px;
  z-index: 10;

  .open {
    width: 22px;
    height: 18px;
    margin: auto;

    .line {
      height: 2px;
      margin-bottom: 6px;
      background: $main-text-color;
      width: 100%;
    }
  }

  .close {
    color: $main-text-color;
  }
}

@media screen and (max-width: 970px) {
  .resp-menu-trigger {
    display: block;
  }
}
</style>