import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueLazyload from 'vue-lazyload'
import Loader from './assets/loader.png';

Vue.config.productionTip = false
Vue.prototype.$isMenuDisplayed = false;

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: Loader,
  loading: Loader,
  attempt: 1
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
